import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Badge from '@mui/material/Badge';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { CalendarEventType, EventTypeWithStatus, HighlightedDay, ProcessedEvent } from 'interfaces/interfaces';
import { themeColors } from 'sitevars';
import { Box, SxProps, Theme } from '@mui/material';
import { useState } from 'react';

function getDaysWithEvents(eventsObject: any, month: Dayjs, eventType: CalendarEventType, highlightColor: string) {
  switch (eventType) {
    case CalendarEventType.GOOGLE: {
      const googleEvents = eventsObject as ProcessedEvent[];
      return googleEvents
        .filter((event) => dayjs(event.start).month() === month.month())
        .map((event) => ({
          day: dayjs(event.start).date(),
          color: highlightColor,
        }));
    }

    case CalendarEventType.SIMPLE: {
      const simpleEvents = eventsObject as string[];
      return simpleEvents
        .filter((event) => dayjs(event).month() === month.month())
        .map((event) => ({
          day: dayjs(event).date(),
          color: highlightColor,
        }));
    }

    case CalendarEventType.EVENT_STATUS: {
      const eventStatusEvents = eventsObject as EventTypeWithStatus[];
      console.log('eventStatusEvents: ', eventStatusEvents);
      const res = eventStatusEvents
        .filter((event) => dayjs(event.date).month() === month.month())
        .map((event) => {
          return {
            day: dayjs(event.date).date(),
            color: event.status ? themeColors.deepGreen : themeColors.lightRed,
          };
        });
      return res;
    }

    default:
      return [];
  }
}

interface ServerDayProps extends PickersDayProps<Dayjs> {
  highlightedDays?: HighlightedDay[];
}

const initialValue = dayjs();

function ServerDay(props: ServerDayProps) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const highlightedDay = highlightedDays.find((highlight) => highlight.day === day.date());
  const isHighlighted = !!highlightedDay && !outsideCurrentMonth;
  const highlightColor = highlightedDay?.color;

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={undefined}
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: isHighlighted ? highlightColor : 'transparent',
          borderRadius: '50%',
          minWidth: 36,
          height: 36,
        },
      }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        sx={{
          bgcolor: isHighlighted ? highlightColor : undefined,
          color: isHighlighted ? 'white' : undefined,
          borderRadius: '50%',
          width: 36,
          height: 36,
          '&.Mui-selected': {
            bgcolor: isHighlighted ? highlightColor : undefined,
          },
        }}
      />
    </Badge>
  );
}

export default function DateCalendarServerRequest({
  events,
  eventType,
  highlightColor = themeColors.muiPrimaryBlue,
  sx = {},
}: {
  events?: any;
  eventType: CalendarEventType;
  highlightColor?: string;
  sx?: SxProps<Theme>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState<HighlightedDay[]>([]);

  const fetchHighlightedDays = (date: Dayjs) => {
    if (events && events.length > 0) {
      setIsLoading(true);
      const daysWithEvents = getDaysWithEvents(events, date, eventType, highlightColor);
      setHighlightedDays(daysWithEvents);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (events && events.length > 0) {
      fetchHighlightedDays(initialValue);
    }
  }, [events]);

  const handleMonthChange = (date: Dayjs) => {
    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  return (
    <Box sx={sx}>
      <DateCalendar
        readOnly
        defaultValue={initialValue}
        loading={isLoading}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        views={['day']}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          } as any,
        }}
      />
    </Box>
  );
}
