import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { themeColors } from 'sitevars';

const TimerComponent = () => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const tick = () => {
      setSeconds((prev) => prev + 1);
    };

    const intervalId = setInterval(tick, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formatTime = (secs: number) => {
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs % 3600) / 60);
    const seconds = secs % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  let color = 'inherit';
  if (seconds > 240) {
    // 40mins
    color = themeColors.cherryRedBright;
  } else if (seconds > 180) {
    // 30mins
    color = themeColors.lightYellow;
  }

  return (
    <>
      <Typography sx={{ fontSize: 12 }}>
        Timer: <span style={{ fontWeight: 'bold', color: color, fontSize: 12 }}> {formatTime(seconds)}</span>
      </Typography>
    </>
  );
};

export default TimerComponent;
