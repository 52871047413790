export const strings = {
  mainSectionSidebarTitle: 'Portal',
  secondarySectionSidebarTitle: 'Notifications',
};

export const MemebershipTypes = {
  INDIVIDUAL: 'INDIVIDUAL',
  BUSINESS: 'BUSINESS',
  ENTERPRISE: 'ENTERPRISE',
  ADMIN: 'ADMIN',
};

//note: this values MUST be the same as MUI6 alert types
export const NotificationTypeEnum = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARN: 'warning',
} as const;

export const SectionTypeEnum = {
  PORTAL: 'portal',
  NOTIFICATIONS: 'notifications',
};

export const PortalSectionTypeEnum = {
  HOME: 'home',
  TRACKER: 'tracker',
  TARGETS: 'targets',
  TESTS: 'tests',
  IDE: 'ide',
  PROBLEMS: 'problems',
  ONBOARDING: 'onboarding',
  JOB_APPLICATIONS: 'job_applications',
  CHECKIN: 'check_in',
  // admin sections
  ADMIN: 'admin',
  ADMIN_TESTS_GRADE: 'adminTestGrade',
  ADMIN_CHECKIN: 'adminCheckin',
  ADMIN_JOBS: 'adminJobs',
};
