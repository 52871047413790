import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

interface AlertConfirmationProps {
  text: React.ReactNode;
  open: boolean;
  setOpen: any;
  status: boolean;
  confirmationFunction: any;
  confirmationText: string;
  canUndoAction?: boolean;
  cancelationText?: string;
}

export default function AlertConfirmation({
  text,
  open,
  setOpen,
  status,
  confirmationFunction,
  confirmationText,
  canUndoAction = false,
  cancelationText = 'No',
}: AlertConfirmationProps) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    confirmationFunction(true);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ padding: 50, wordWrap: 'break-word' }}>
        {text}
        {!canUndoAction && <DialogContentText>You cannot undo this action.</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{cancelationText}</Button>
        <Button onClick={handleConfirmation} autoFocus>
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
