import React from 'react';
import { Box, Typography } from '@mui/material';
import { UserData } from 'interfaces/interfaces'; // Adjust the import according to your project structure

interface UserDataComponentProps {
  user: UserData;
}

const UserDataComponent: React.FC<UserDataComponentProps> = ({ user }) => {
  return (
    <Box sx={{ padding: 2, border: '1px solid #616161', borderRadius: '4px' }}>
      <Typography variant="h6">User Data</Typography>
      <Typography variant="body1">
        <strong>Email:</strong> {user.email}
      </Typography>
      <Typography variant="body1">
        <strong>Name:</strong> {user.name}
      </Typography>
      {/* <Typography variant="body1">
        <strong>Given Name:</strong> {user.given_name}
      </Typography>
      <Typography variant="body1">
        <strong>Family Name:</strong> {user.family_name}
      </Typography> */}
      <Typography variant="body1">
        <strong>LC User:</strong> {user.lc_user}
      </Typography>
      {/* <Typography variant="body1">
        <strong>Role:</strong> {user.role}
      </Typography> */}
      <Typography variant="body1">
        <strong>Start Date:</strong> {user.start_date ? user.start_date.toString() : 'N/A'}
      </Typography>
      <Typography variant="body1">
        <strong>End Date:</strong> {user.end_date ? user.end_date.toString() : 'N/A'}
      </Typography>
      <Typography variant="body1">
        <strong>User ID:</strong> {user.user_id}
      </Typography>
    </Box>
  );
};

export default UserDataComponent;
