import { Box } from '@mui/material';
import { PageHeader } from 'components/common/PageHeader';
import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button, useTheme } from '@mui/material';
import { areAllFileldsFilled, getMonthNumberFromEpoch, getUserId } from 'utils/Helpers';
import { NotificationTypeEnum } from 'strings';
import ToastAction from 'components/common/ToastAction';
import { AppContext } from 'context/context';
import axios from 'axios';
import { apiServerLink, themeColors } from 'sitevars';
import Confetti from 'react-confetti';
import DateCalendarServerRequest from 'components/common/Celendar';
import { CalendarEventType, CheckinType, DiaryEntry, EventTypeWithStatus } from 'interfaces/interfaces';
import Loading from 'components/common/Loading';
import DiaryEntriesList from 'components/DiaryEntriesList';
import EntryDrawer from 'components/EntryDrawer';

export default function CheckIn() {
  const { state } = useContext(AppContext);
  const theme = useTheme();
  const [diaryEntries, setDiaryEntries] = useState<DiaryEntry[]>([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [hasDailyCheckIn, setHasDailyCheckIn] = useState(false);
  const [nextCheckType, setNextCheckType] = useState<string>('');
  const [enableConfetti, setEnableConfetti] = useState(false);
  const [allCheckinDates, setAllCheckinDates] = useState<EventTypeWithStatus[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<DiaryEntry | null>(null);
  const [averageRating, setAverageRating] = useState<number>(0);
  const [isEntrySaved, setIsEntrySaved] = useState<boolean>(true);
  //notification toast vars
  const [notification, setNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const [notificationType, setNotificationType] = useState<
    typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum]
  >(NotificationTypeEnum.INFO);
  const user_id = getUserId(state);

  useEffect(() => {
    if (user_id) {
      fetchEntries(user_id);
      checkIfDailyCheckinExist(user_id);
      // getAverageRating(user_id);
    } else {
      // notification for error
      setNotification(true);
      setNotificationText('Sorry something went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  }, []);

  const getAverageRating = async (user_id: number) => {
    try {
      const response = await axios.get(`${apiServerLink}/api/get_checkin_average_rating`, {
        params: {
          user_id: user_id,
        },
      });
      const average_rating = response.data.average_rating;
      setAverageRating(average_rating);
    } catch (e) {}
  };

  const checkIfDailyCheckinExist = async (user_id: number) => {
    try {
      const response = await axios.get(`${apiServerLink}/api/has_checkin_today`, {
        params: {
          user_id: user_id,
        },
      });

      setHasDailyCheckIn(response.data.has_checkin);
    } catch (error) {}
  };

  const fetchEntries = async (user_id: number) => {
    try {
      const response = await axios.get(`${apiServerLink}/api/get_checkins`, {
        params: {
          user_id: user_id,
        },
      });
      const entryData: DiaryEntry[] = response.data;
      const calendarEvents = transformDiaryEntriesToEvents(entryData);
      setAllCheckinDates(calendarEvents);

      setDiaryEntries(entryData);
    } catch (error) {
      // notification for error
      setNotification(true);
      setNotificationText('Sorry something went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  function transformDiaryEntriesToEvents(diaryEntries: DiaryEntry[]): EventTypeWithStatus[] {
    return diaryEntries.map((entry) => {
      return {
        date: entry.date,
        status: areAllFileldsFilled(entry),
      };
    });
  }

  const addNewEntry = async () => {
    const response = await axios.post(`${apiServerLink}/api/create_checkin`, {
      user_id,
    });
  };

  const handleEntryChange = (
    id: number,
    field: keyof DiaryEntry,
    value: any,
    questionIndex?: number // Optional parameter to handle question index
  ) => {
    setDiaryEntries((prevEntries) =>
      prevEntries.map((entry) => {
        if (entry.id === id) {
          // Check if the field is 'questions' and we have a question index
          if (field === 'questions' && typeof questionIndex === 'number') {
            const updatedQuestions = entry.questions.map((question, index) =>
              index === questionIndex ? { ...question, answer: value } : question
            );
            return { ...entry, questions: updatedQuestions };
          }

          // Update the entry field if it's not 'questions'
          return { ...entry, [field]: value };
        }
        return entry;
      })
    );

    setIsEntrySaved(false);

    // Update the selectedEntry if it matches the id
    if (selectedEntry && selectedEntry.id === id) {
      if (field === 'questions' && typeof questionIndex === 'number') {
        const updatedQuestions = selectedEntry.questions.map((question, index) =>
          index === questionIndex ? { ...question, answer: value } : question
        );
        setSelectedEntry({ ...selectedEntry, questions: updatedQuestions });
      } else {
        setSelectedEntry({ ...selectedEntry, [field]: value });
      }
    }
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(`${apiServerLink}/api/update_checkin`, {
        user_id,
        id: selectedEntry?.id,
        notes: selectedEntry?.notes,
        rating: selectedEntry?.rating,
        questions: selectedEntry?.questions,
      });
      if (response.status === 200) {
        if (selectedEntry) {
          updateCheckinStatus(selectedEntry);

          setDiaryEntries((prevEntries) => {
            const index = prevEntries.findIndex((entry) => entry.id === selectedEntry.id);

            if (index !== -1) {
              const updatedEntries = [...prevEntries];
              updatedEntries[index] = selectedEntry;
              return updatedEntries;
            } else {
              return [selectedEntry, ...prevEntries];
            }
          });

          setIsEntrySaved(true);
          setDrawerOpen(false);

          if (!hasDailyCheckIn) {
            setEnableConfetti(true);
            setHasDailyCheckIn(true);
          }

          // notification for success
          setNotification(true);
          setNotificationText('Entry saved successfully.');
          setNotificationType(NotificationTypeEnum.SUCCESS);
        } else {
          // notification for error
          setNotification(true);
          setNotificationText('Sorry sommething went wrong. Please try again.');
          setNotificationType(NotificationTypeEnum.ERROR);
        }
      }
    } catch (e) {
      // notification for error
      setNotification(true);
      setNotificationText('Sorry sommething went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const updateCheckinStatus = (selectedEntry: DiaryEntry) => {
    const existingEntryIndex = allCheckinDates.findIndex((entry) => entry.date === selectedEntry.date);

    const entryStatus = areAllFileldsFilled(selectedEntry);

    if (existingEntryIndex !== -1) {
      const updatedCheckinDates = [...allCheckinDates];
      updatedCheckinDates[existingEntryIndex] = {
        date: selectedEntry.date,
        status: entryStatus,
      };

      setAllCheckinDates(updatedCheckinDates);
    } else {
      setAllCheckinDates([...allCheckinDates, { date: selectedEntry.date, status: entryStatus }]);
    }
  };

  const getNextMeetingSlotDates = () => {
    if (user_id) {
      const currentEpoch = (Date.now() as unknown) as string;
      const currentDate = new Date();
      const currentDay = currentDate.getDate();
      const monthName = getMonthNumberFromEpoch(currentEpoch);
      let responseDates = '';

      if (user_id % 2 === 0) {
        // Even user
        if (currentDay > 23 || currentDay <= 5) {
          responseDates = '1-5'; // Monthly Review
        } else {
          responseDates = '19-23'; // Biweekly Review
        }
      } else {
        // Odd user
        if (currentDay > 28 || currentDay <= 10) {
          responseDates = '6-10'; // Monthly Review
        } else {
          responseDates = '24-28'; // Biweekly Review
        }
      }

      return `${responseDates} ${monthName}`;
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    let responseDates = '';

    if (user_id && user_id % 2 === 0) {
      // Even user
      if (currentDay > 23 || currentDay <= 5) {
        responseDates = '1-5'; // Monthly Review
        if (nextCheckType !== CheckinType.MONTHLY) {
          setNextCheckType(CheckinType.MONTHLY);
        }
      } else {
        responseDates = '19-23'; // Biweekly Review
        if (nextCheckType !== CheckinType.BIWEEKLY) {
          setNextCheckType(CheckinType.BIWEEKLY);
        }
      }
    } else {
      // Odd user
      if (currentDay > 28 || currentDay <= 10) {
        responseDates = '6-10'; // Monthly Review
        setNextCheckType(CheckinType.MONTHLY);
      } else {
        responseDates = '24-28'; // Biweekly Review
        setNextCheckType(CheckinType.BIWEEKLY);
      }
    }
  }, [user_id, nextCheckType]);

  const handleOpenDrawer = (entry: DiaryEntry) => {
    setSelectedEntry(entry);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = async () => {
    if (isEntrySaved) {
      setDrawerOpen(false);
    } else {
      // if user didn't press save
      // notification for error
      setNotification(true);
      setNotificationText('Please save your entry.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationText('');
    setNotificationType(NotificationTypeEnum.INFO);
  };

  return (
    <>
      {notification && (
        <ToastAction
          message={notificationText}
          type={notificationType}
          open={notification}
          close={handleCloseNotification}
        />
      )}

      <div>
        <PageHeader title="Check In Diary" />
        <div style={{ display: 'flex' }}>
          {enableConfetti && <Confetti recycle={false} />}
          {/* Left panel with Accordion list */}
          <Box
            sx={{
              width: '30vw',
            }}
          >
            {/* <Button variant="contained" onClick={addNewEntry} fullWidth>
              Add New Entry
            </Button> */}

            {/* List of entries */}
            <Box
              sx={{
                height: '88vh',
                overflowY: 'auto',
                padding: '8px',
                paddingTop: '0px',
                borderRadius: '16px',
                boxShadow: 4,
                backgroundColor: theme.palette.background.paper,
              }}
            >
              {diaryEntries.length > 0 && (
                <DiaryEntriesList diaryEntries={diaryEntries} handleOpen={handleOpenDrawer} />
              )}
              {diaryEntries.length <= 0 && <Loading />}
            </Box>
          </Box>
          {/* Drawer for the selected entry */}
          <EntryDrawer
            isDrawerOpen={isDrawerOpen}
            handleCloseDrawer={handleCloseDrawer}
            selectedEntry={selectedEntry}
            handleEntryChange={handleEntryChange}
            isAdmin={false}
            handleSaveClick={handleSaveClick}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '88vh',
              paddingTop: 0,
              backgroundColor: '',
            }}
          >
            <Typography variant="h5" sx={{ backgroundColor: '' }}>
              Daily Check In Stats
            </Typography>

            <DateCalendarServerRequest
              events={allCheckinDates}
              highlightColor={themeColors.deepGreen}
              eventType={CalendarEventType.EVENT_STATUS}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                backgroundColor: '',
                width: '40vw',
                overflow: 'hidden',
              }}
            />
            {/* {averageRating >= 1 && <RatingComponent rating={averageRating} />} */}
            {/* {pieChartProps && pieChartProps.length > 0 && <PieChartVisualsization chartProps={pieChartProps} />} */}
            <Box
              sx={{
                borderRadius: '16px',
                padding: 3,
                boxShadow: 2,
                backgroundColor: theme.palette.background.paper,
                width: '26vw',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="h5" sx={{ backgroundColor: '' }}>
                Set up a 1-1 Meeting
              </Typography>
              <Typography sx={{ backgroundColor: '', mt: 2, mb: 3 }}>
                Book a {nextCheckType} 1-1 with your mentor between{' '}
                <span style={{ color: themeColors.muiPrimaryBlue }}>{getNextMeetingSlotDates()}</span>. <br />
                <br /> Ensure that you complete your{' '}
                {nextCheckType === CheckinType.BIWEEKLY ? CheckinType.WEEKLY : nextCheckType} check-in before the
                meeting.
              </Typography>
              <Box sx={{ marginTop: 'auto', alignSelf: 'center' }}>
                <Button
                  onClick={() => {
                    window.open(
                      'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3rZbV1HENn-GdSeW4xlSaa1hQ1qRpeZW0i3z2TgxMM5rqGKbCjb-XdAkLfDXI6qXlAUXVvpsX9',
                      '_blank'
                    );
                  }}
                  style={{
                    textTransform: 'none',
                    borderRadius: '24px',
                    paddingLeft: 15,
                    paddingRight: 15,
                    backgroundColor: themeColors.primaryBlue,

                    color: '#fff',
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = themeColors.muiPrimaryBlue;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = themeColors.primaryBlue;
                  }}
                >
                  Book meeting
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}
