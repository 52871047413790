import React from 'react';
import { Drawer, Box, Typography, TextField, Rating, IconButton, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { CheckinType, DiaryEntry } from 'interfaces/interfaces';
import {
  formatEpochToDateDDMonthYearTime,
  formatEpochToDayOfWeek,
  getWeekNumber,
  getMonthNumberFromEpoch,
} from 'utils/Helpers';

interface EntryDrawerProps {
  isDrawerOpen: boolean;
  handleCloseDrawer: () => void;
  selectedEntry: DiaryEntry | null;
  handleEntryChange: any;
  isAdmin: boolean;
  handleSaveClick: () => void;
}

const EntryDrawer: React.FC<EntryDrawerProps> = ({
  isDrawerOpen,
  handleCloseDrawer,
  selectedEntry,
  handleEntryChange,
  isAdmin,
  handleSaveClick,
}) => {
  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <Box
        sx={{
          width: '50vw',
          height: '50vh',
          padding: '16px',
          paddingTop: '5px',
          margin: 3,
        }}
      >
        {selectedEntry && (
          <>
            {selectedEntry.type === CheckinType.DAILY && (
              <Typography variant="h5" gutterBottom>
                Daily Check In - {formatEpochToDateDDMonthYearTime(selectedEntry.date)} (
                {formatEpochToDayOfWeek(selectedEntry.date)})
              </Typography>
            )}
            {selectedEntry.type === CheckinType.WEEKLY && (
              <Typography variant="h5" gutterBottom>
                Weekly Check In - {formatEpochToDateDDMonthYearTime(selectedEntry.date)} - Week{' '}
                {getWeekNumber(selectedEntry.date)}
              </Typography>
            )}
            {selectedEntry.type === CheckinType.MONTHLY && (
              <Typography variant="h5" gutterBottom>
                Monthly Check In - {getMonthNumberFromEpoch(selectedEntry.date)} Review
              </Typography>
            )}

            {selectedEntry.questions.map((questionData: any, index: number) => (
              <TextField
                key={index}
                label={questionData.question}
                multiline
                rows={2}
                value={questionData.answer}
                onChange={(e) => handleEntryChange(selectedEntry.id, 'questions', e.target.value, index)}
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: isAdmin,
                }}
              />
            ))}

            {selectedEntry.type === CheckinType.DAILY && (
              <Typography sx={{ paddingTop: 1 }} component="legend">
                How satisfied are you with your daily progress? Be honest.
              </Typography>
            )}
            {selectedEntry.type === CheckinType.WEEKLY && (
              <Typography sx={{ paddingTop: 1 }} component="legend">
                How do you rate your weekly performance?
              </Typography>
            )}
            {selectedEntry.type === CheckinType.MONTHLY && (
              <Typography sx={{ paddingTop: 1 }} component="legend">
                How do you evaluate your monthly performance overall?
              </Typography>
            )}
            <Rating
              sx={{ fontSize: 30 }}
              name={`rating-${selectedEntry.id}`}
              value={selectedEntry.rating}
              onChange={(event, newValue) => handleEntryChange(selectedEntry.id, 'rating', newValue)}
            />

            <TextField
              label="Any notes for yourself?"
              multiline
              rows={2}
              value={selectedEntry.notes}
              onChange={(e) => handleEntryChange(selectedEntry.id, 'notes', e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: isAdmin,
              }}
            />

            {selectedEntry.type !== CheckinType.DAILY && (
              <TextField
                label="Comments from Mentor"
                multiline
                rows={2}
                value={selectedEntry.comments}
                fullWidth
                margin="normal"
                onChange={(e) => handleEntryChange(selectedEntry.id, 'comments', e.target.value)}
                InputProps={{
                  readOnly: !isAdmin,
                }}
              />
            )}

            <Box sx={{ marginTop: 0 }}>
              <Tooltip title="Save changes">
                <IconButton onClick={handleSaveClick} aria-label="save" color="primary">
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default EntryDrawer;
