import React, { useEffect, useState } from 'react';
import { Typography, Container, FormControl, InputLabel, Select, MenuItem, Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Spinner from 'components/common/Spinner';
import DataTable from 'components/DataTable';
import { AppContext } from 'context/context';
import { PageHeader } from 'components/common/PageHeader';
import { UserData, TestData, LcUserIdData } from 'interfaces/interfaces';
import { localStorageKeys, apiServerLink } from 'sitevars';
import { getUserEmail } from 'utils/Helpers';

const Admin: React.FC = () => {
  const [lcUsers, setLcUsers] = useState<LcUserIdData[]>([]);
  const [selectedLcUser, setSelectedLcUser] = useState<string>('');
  const [userChanged, setUserChanged] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [testData, setTestData] = useState<TestData[]>([]);
  const [selectedTestData, setSelectedTestData] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { state } = React.useContext(AppContext);

  const [user, setUser] = useState<UserData | null>(null);

  useEffect(() => {
    const storedData = localStorage.getItem(localStorageKeys.userData);
    if (storedData) {
      const userData = JSON.parse(storedData);
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    const email = getUserEmail(state);
    if (email && user?.role === 'admin') {
      setLoading(true);
      axios
        .get(`${apiServerLink}/solved_failed_data`, {
          params: { user_id: selectedLcUser },
        })
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('There was an error fetching the data for daily logger!', error);
          setLoading(false);
        });
    }
  }, [selectedLcUser, user, state.userEmail]);

  useEffect(() => {
    if (user?.role === 'admin') {
      axios
        .get(`${apiServerLink}/api/lc_users`)
        .then((response) => {
          setLcUsers(response.data);
        })
        .catch((error) => {
          console.error('There was an error fetching the data for admin lc users!', error);
        });
    }
  }, [user]);

  useEffect(() => {
    axios
      .get<TestData[]>(`${apiServerLink}/api/tests/need_grading`)
      .then((response) => {
        setTestData(response.data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleLcUserChange = (event: any) => {
    setSelectedLcUser(event.target.value as string);
    setUserChanged(true);
  };

  const handleTestDataChange = (event: any) => {
    const selectedName = event.target.value as string;
    const selectedData = testData.find((item) => item.name === selectedName);
    if (selectedData) {
      setSelectedTestData(selectedName);
      localStorage.setItem('selectedEmail', selectedData.email);
      localStorage.setItem('selectedName', selectedData.name);
      localStorage.setItem('selectedDifficulty', selectedData.difficulty);
      localStorage.setItem('selectedTopicTags', selectedData.topicTags);
      alert(`Stored ${selectedData.name} details in local storage.`);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <PageHeader title="Admin LC User Tracker" />
      <div className="flex space-x-4 mb-4">
        <FormControl sx={{ width: '40vw', paddingBottom: 3 }}>
          <InputLabel id="graded-dropdown-label">Select User</InputLabel>
          <Select
            labelId="graded-dropdown-label"
            id="graded-dropdown"
            value={selectedLcUser ?? ''} // Set the selected user_id, or '' if none is selected
            label="Select a LC user"
            onChange={handleLcUserChange} // Handle user change
          >
            {lcUsers.map((item) => (
              <MenuItem key={item.user_id} value={item.user_id}>
                {item.lc_user} {/* Display the lc_user as the menu item */}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="mt-6">{loading ? <Spinner /> : <>{data && <DataTable data={data} />}</>}</div>
    </div>
  );
};

export default Admin;
