import { ChartProps } from 'components/charts/PieChartVisualsization';
import { DiaryEntry, LeetcodeLevels, TestProps, TopicStatusTypes, UserData } from 'interfaces/interfaces';
import { localStorageKeys, themeColors } from 'sitevars';

export const getDifficultyIconColor = (difficulty?: LeetcodeLevels): string => {
  switch (difficulty) {
    case LeetcodeLevels.EASY:
      return themeColors.lightGreen;
    case LeetcodeLevels.MEDIUM:
      return themeColors.lightYellow;
    case LeetcodeLevels.HARD:
      return themeColors.lightRed;
    default:
      return themeColors.lightGrey;
  }
};

export const mapTopicStatus = (status: TopicStatusTypes): string => {
  switch (status) {
    case TopicStatusTypes.CURRENT:
      return 'In Progress';
    case TopicStatusTypes.FINISHED:
      return 'Completed';
    case TopicStatusTypes.FUTURE:
      return 'Locked';
    default:
      return 'Locked'; // Handle any unexpected values gracefully
  }
};

export const mapTopicStatusToPointer = (status: TopicStatusTypes): string => {
  switch (status) {
    case TopicStatusTypes.CURRENT:
      return 'pointer';
    case TopicStatusTypes.FINISHED:
      return 'pointer';
    case TopicStatusTypes.FUTURE:
      return 'not-allowed';
    default:
      return 'Unknown'; // Handle any unexpected values gracefully
  }
};

export const getTopicStatusColor = (status?: TopicStatusTypes): string => {
  switch (status) {
    case TopicStatusTypes.CURRENT:
      return themeColors.dimBlue;
    case TopicStatusTypes.FINISHED:
      return themeColors.lightGreen;
    case TopicStatusTypes.FUTURE:
      return themeColors.dimGrey;
    default:
      return themeColors.dimGrey;
  }
};

export const countTestStatus = (data: TestProps[]): ChartProps[] => {
  let lockedCount = 0;
  let unlockedCount = 0;
  let completedCount = 0;

  data.forEach((test) => {
    if (test.tests === -1) {
      lockedCount++;
    } else if (test.tests === 0) {
      unlockedCount++;
    } else if (test.tests === 1) {
      completedCount++;
    }
  });

  const chartProps: ChartProps[] = [
    { id: 1, value: completedCount, label: 'Completed', color: themeColors.lightGreen },
    { id: 2, value: unlockedCount, label: 'To do', color: themeColors.dimBlue },
    { id: 3, value: lockedCount, label: 'Locked', color: themeColors.dimLightGrey },
  ];

  return chartProps;
};

export function formatEpochToDate(epoch: number): string {
  const date = new Date(epoch);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
}

export function getDaysSinceUserIsMember(startDateEpoch: number): number {
  const currentDateMillis = new Date().getTime();
  const differenceMillis = currentDateMillis - startDateEpoch;
  const days = Math.floor(differenceMillis / (1000 * 60 * 60 * 24));
  return days - 1;
}

export function generateId(): number {
  return Math.floor(100000 + Math.random() * 900000); // Generates a random number between 100000 and 999999 (6 digits)
}

export function getUserId(state: any): number | null {
  const userDataString = localStorage.getItem(localStorageKeys.userData);
  const savedUserData: UserData = userDataString !== null ? JSON.parse(userDataString) : null;
  const userId =
    savedUserData && savedUserData.user_id !== undefined && savedUserData.user_id !== null
      ? savedUserData.user_id
      : state.userId && state.userId !== undefined && state.userId !== ''
      ? state.userId
      : null;
  return userId;
}

export function getUserStartDate(state: any): number | null {
  const userDataString = localStorage.getItem(localStorageKeys.userData);
  const savedUserData: UserData = userDataString !== null ? JSON.parse(userDataString) : null;
  const userStartDate =
    savedUserData && savedUserData.start_date !== undefined && savedUserData.start_date !== null
      ? savedUserData.start_date
      : null;
  return userStartDate;
}

export function getUserEmail(state: any): number | null {
  const userDataString = localStorage.getItem(localStorageKeys.userData);
  const savedUserData: UserData = userDataString !== null ? JSON.parse(userDataString) : null;
  const userEmail =
    savedUserData && savedUserData.email !== undefined && savedUserData.email !== null && savedUserData.email !== ''
      ? savedUserData.email
      : state.userEmail && state.userEmail !== undefined && state.userEmail !== ''
      ? state.userEmail
      : null;
  return userEmail;
}

export function formatEpochToDateDDMonthYearTime(epochTime: string) {
  return new Date(epochTime).toLocaleString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    // hour: '2-digit',
    // minute: '2-digit',
    // hour12: false, // Use 24-hour format
  });
}

export function formatEpochToDateDDMonthYear(epochTime: string) {
  return new Date(epochTime).toLocaleString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
}

export function formatEpochToDayOfWeek(epochTime: string) {
  const date = new Date(epochTime);

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const day = date.getDay(); // date.getDay() for local time

  return daysOfWeek[day];
}

export function getWeekNumber(epochTime: string): number {
  const date = new Date(epochTime);

  // Create a new date object and adjust to Thursday of the current week (ISO 8601 requires the week containing Thursday)
  const tempDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  tempDate.setDate(tempDate.getDate() + 4 - (tempDate.getDay() || 7));

  // Get the start of the year (January 1st of the current year)
  const startOfYear = new Date(tempDate.getFullYear(), 0, 1);

  // Calculate the difference in days from the start of the year
  const diffInMillis = tempDate.getTime() - startOfYear.getTime(); // Subtract in milliseconds
  const dayOfYear = Math.floor(diffInMillis / 86400000) + 1; // Convert milliseconds to days

  return Math.ceil(dayOfYear / 7);
}

export function capitalizeWord(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function getMonthNumberFromEpoch(epoch: string) {
  const date = new Date(epoch);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthName = monthNames[date.getMonth()];
  const year = date.getFullYear();
  return `${monthName} ${year}`;
}

export function areAllFileldsFilled(selectedEntry: DiaryEntry) {
  return (
    selectedEntry.rating > 0 &&
    selectedEntry.questions.every((entry) => entry.answer !== '' && entry.answer !== null && entry.answer !== undefined)
  );
}
