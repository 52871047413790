import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { UserData } from 'interfaces/interfaces'; // Adjust the import according to your project structure

interface UserSelectProps {
  users: UserData[];
  selectedUser: number | undefined;
  setSelectedUser: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectedUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
}

const UserSelect: React.FC<UserSelectProps> = ({ users, selectedUser, setSelectedUser, setSelectedUserData }) => {
  const handleUserChange = (event: any) => {
    const userId = event.target.value as number;
    setSelectedUser(userId);

    const userData = users.find((user) => user.user_id === userId) || null;
    setSelectedUserData(userData);
  };

  return (
    <div className="flex space-x-4 mb-4">
      <FormControl sx={{ width: '40vw', paddingBottom: 1 }}>
        <InputLabel id="graded-dropdown-label">Select User</InputLabel>
        <Select
          labelId="graded-dropdown-label"
          id="graded-dropdown"
          value={selectedUser ?? ''}
          label="Select a LC user"
          onChange={handleUserChange} // Attach the handleUserChange function
        >
          {users.map((item) => (
            <MenuItem key={item.user_id} value={item.user_id}>
              {item.name} - {item.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default UserSelect;
