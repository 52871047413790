import UserDataComponent from 'components/UserDataComponent';
import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import axios from 'axios';
import { PageHeader } from 'components/common/PageHeader';
import { AppContext } from 'context/context';
import { DiaryEntry, TestData, UserData } from 'interfaces/interfaces';
import React, { useState, useEffect, useContext } from 'react';
import { localStorageKeys, apiServerLink } from 'sitevars';
import { getUserId } from 'utils/Helpers';
import UserSelect from 'components/admin/UserSelect';
import DiaryEntriesList from 'components/DiaryEntriesList';
import EntryDrawer from 'components/EntryDrawer';
import ToastAction from 'components/common/ToastAction';
import { NotificationTypeEnum } from 'strings';

export default function AdminReviewCheckIn() {
  const { state } = useContext(AppContext);
  const [users, setUsers] = useState<UserData[]>([]);
  const [selectedUser, setSelectedUser] = useState<number>();
  const [selectedUserData, setSelectedUserData] = useState<UserData | null>(null);
  const [diaryEntries, setDiaryEntries] = useState<DiaryEntry[]>([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isEntrySaved, setIsEntrySaved] = useState<boolean>(true);
  const [selectedEntry, setSelectedEntry] = useState<DiaryEntry | null>(null);

  //notification toast vars
  const [notification, setNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationType, setNotificationType] = useState<
    typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum]
  >(NotificationTypeEnum.INFO);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apiServerLink}/api/all_users`, {
        params: {
          user_id: getUserId(state),
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('There was an error fetching the data for admin lc users!', error);
    }
  };

  const fetchDiaryEntries = async (user_id: number) => {
    try {
      const response = await axios.get(`${apiServerLink}/api/get_checkins`, {
        params: {
          user_id: user_id,
        },
      });
      const entryData: DiaryEntry[] = response.data;
      setDiaryEntries(entryData);
    } catch (error) {
      // notification for error
      console.log('error:', error);
    }
  };

  useEffect(() => {
    fetchDiaryEntries(selectedUser!);
  }, [selectedUser]);

  const handleOpenDrawer = (entry: DiaryEntry) => {
    setSelectedEntry(entry);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = async () => {
    if (isEntrySaved) {
      setDrawerOpen(false);
    } else {
      // if user didn't press save
      // notification for error
      setNotification(true);
      setNotificationText('Please save your feedback.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const handleEntryChange = (id: number, field: keyof DiaryEntry, value: any, questionIndex?: number) => {
    setDiaryEntries((prevEntries) =>
      prevEntries.map((entry) => {
        if (entry.id === id) {
          // Handling question updates
          if (field === 'questions' && typeof questionIndex === 'number') {
            const updatedQuestions = entry.questions.map((question, index) =>
              index === questionIndex ? { ...question, answer: value } : question
            );
            return { ...entry, questions: updatedQuestions };
          }
          // Handling other field updates
          return { ...entry, [field]: value };
        }
        return entry;
      })
    );

    // Update selected entry if applicable
    if (selectedEntry && selectedEntry.id === id) {
      if (field === 'questions' && typeof questionIndex === 'number') {
        const updatedQuestions = selectedEntry.questions.map((question, index) =>
          index === questionIndex ? { ...question, answer: value } : question
        );
        setSelectedEntry({ ...selectedEntry, questions: updatedQuestions });
      } else {
        setSelectedEntry({ ...selectedEntry, [field]: value });
      }
    }

    setIsEntrySaved(false);
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(`${apiServerLink}/api/update_checkin_from_mentor`, {
        user_id: selectedUser,
        id: selectedEntry?.id,
        comments: selectedEntry?.comments,
      });
      if (response.status === 200) {
        if (selectedEntry) {
          setDiaryEntries((prevEntries) => {
            const index = prevEntries.findIndex((entry) => entry.id === selectedEntry.id);

            if (index !== -1) {
              const updatedEntries = [...prevEntries];
              updatedEntries[index] = selectedEntry;
              return updatedEntries;
            } else {
              return [selectedEntry, ...prevEntries];
            }
          });

          setIsEntrySaved(true);
          setDrawerOpen(false);

          // notification for success
          setNotification(true);
          setNotificationText('Comments saved successfully.');
          setNotificationType(NotificationTypeEnum.SUCCESS);
        } else {
          // notification for error
          setNotification(true);
          setNotificationText('Sorry sommething went wrong. Please try again.');
          setNotificationType(NotificationTypeEnum.ERROR);
        }
      }
    } catch (e) {
      // notification for error
      setNotification(true);
      setNotificationText('Sorry sommething went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationText('');
    setNotificationType(NotificationTypeEnum.INFO);
  };

  return (
    <>
      {notification && (
        <ToastAction
          message={notificationText}
          type={notificationType}
          open={notification}
          close={handleCloseNotification}
        />
      )}

      <div>
        <Stack spacing={1} sx={{ backgroundColor: '', paddingBottom: 0 }}>
          <PageHeader title="Admin Grade Check Ins" />
          <div className="flex space-x-4 mb-4">
            <UserSelect
              users={users}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              setSelectedUserData={setSelectedUserData}
            />
          </div>
          {selectedUserData && <UserDataComponent user={selectedUserData} />}
          {diaryEntries && (
            <Box
              sx={{
                height: '50vh',
                overflowY: 'auto',
                boxShadow: 4,
                border: '1px solid #616161',
              }}
            >
              <DiaryEntriesList diaryEntries={diaryEntries} handleOpen={handleOpenDrawer} isAdmin={true} />
            </Box>
          )}
          {/* Drawer for the selected entry */}

          <EntryDrawer
            isDrawerOpen={isDrawerOpen}
            handleCloseDrawer={handleCloseDrawer}
            selectedEntry={selectedEntry}
            handleEntryChange={handleEntryChange}
            isAdmin={true}
            handleSaveClick={handleSaveClick}
          />
        </Stack>
      </div>
    </>
  );
}
