import UserDataComponent from 'components/UserDataComponent';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import { PageHeader } from 'components/common/PageHeader';
import { AppContext } from 'context/context';
import {
  DiaryEntry,
  jobApplicationOutcome,
  jobStatusOptions,
  JobSummary,
  TestData,
  UserData,
} from 'interfaces/interfaces';
import React, { useState, useEffect, useContext } from 'react';
import { localStorageKeys, apiServerLink } from 'sitevars';
import { getUserId } from 'utils/Helpers';
import UserSelect from 'components/admin/UserSelect';
import DiaryEntriesList from 'components/DiaryEntriesList';
import EntryDrawer from 'components/EntryDrawer';
import ToastAction from 'components/common/ToastAction';
import { NotificationTypeEnum } from 'strings';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
  GridCellParams,
} from '@mui/x-data-grid';
import JobsTable from 'components/JobsTable';
import { useNavigate } from 'react-router-dom';

export default function AdminReviewJobs() {
  const { state } = useContext(AppContext);
  const [users, setUsers] = useState<UserData[]>([]);
  const [selectedUser, setSelectedUser] = useState<number>();
  const [selectedUserData, setSelectedUserData] = useState<UserData | null>(null);
  const [selectedUserjobData, setSelectedUserJobData] = useState<JobSummary[] | null>(null);
  const navigate = useNavigate();

  //notification toast vars
  const [notification, setNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationType, setNotificationType] = useState<
    typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum]
  >(NotificationTypeEnum.INFO);

  const user_id = getUserId(state);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apiServerLink}/api/all_users`, {
        params: {
          user_id: getUserId(state),
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('There was an error fetching the data for admin lc users!', error);
    }
  };

  const fetchJobs = async (user_id: number) => {
    try {
      const response = await axios.get(`${apiServerLink}/api/get_jobs/${user_id}`);
      const jobsData: JobSummary[] = response.data;
      setSelectedUserJobData(jobsData);
    } catch (error) {
      // console.error('Error fetching jobs data:', error);
      // notification for error
      setNotification(true);
      setNotificationText('Sorry something went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      fetchJobs(selectedUser!);
    }
  }, [selectedUser]);

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationText('');
    setNotificationType(NotificationTypeEnum.INFO);
  };

  const handleMoreClick = (id: number) => () => {
    const path = `/jobs-tracker/${id}/${selectedUser}`; // TODO this is not working and appending the path to the current path when I pass navigate( `${routes.jobsTracker}/${id}`);
    // navigate(path);
    window.open(path, '_blank');
  };

  return (
    <>
      {notification && (
        <ToastAction
          message={notificationText}
          type={notificationType}
          open={notification}
          close={handleCloseNotification}
        />
      )}

      <div>
        <Stack spacing={1} sx={{ backgroundColor: '', paddingBottom: 30 }}>
          <PageHeader title="Admin Review Job Applications" />
          <div className="flex space-x-4 mb-4">
            <UserSelect
              users={users}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              setSelectedUserData={setSelectedUserData}
            />
          </div>
          {selectedUserData && <UserDataComponent user={selectedUserData} />}

          {selectedUserjobData && (
            <>
              <TableContainer component={Paper} style={{ height: '50vh' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '25%' }}>Company</TableCell>
                      <TableCell sx={{ width: '20%' }}>Role</TableCell>
                      <TableCell sx={{ width: '15%' }}>Status</TableCell>
                      <TableCell sx={{ width: '15%' }}>Date</TableCell>
                      <TableCell sx={{ width: '10%' }}>Application Type</TableCell>
                      <TableCell sx={{ width: '5%' }}>Outcome</TableCell>
                      <TableCell sx={{ width: '5%' }}>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedUserjobData && selectedUserjobData.length > 0 ? (
                      selectedUserjobData.map((jobSummary) => (
                        <TableRow key={jobSummary.id}>
                          <TableCell>{jobSummary.company}</TableCell>
                          <TableCell>{jobSummary.role}</TableCell>
                          <TableCell>{jobSummary.status}</TableCell>
                          <TableCell>{jobSummary.date}</TableCell>
                          <TableCell>{jobSummary.applicationType}</TableCell>
                          <TableCell>{jobSummary.outcome}</TableCell>
                          <TableCell>
                            {' '}
                            <Button
                              onClick={handleMoreClick(jobSummary.id)}
                              sx={{
                                textTransform: 'none',

                                margin: 0,
                                padding: 0,
                                borderRadius: 30,
                                paddingX: 1,
                                fontWeight: 0,
                                marginBottom: 1,
                              }}
                            >
                              See more
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          No job summary data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Stack>
      </div>
    </>
  );
}
