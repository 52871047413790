import { Stack } from '@mui/material';
import { PageHeader } from 'components/common/PageHeader';

import React, { useState } from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Card,
  CardContent,
  Snackbar,
  Alert,
} from '@mui/material';

interface Answer {
  id: number;
  text: string;
}

interface Question {
  id: number;
  questionText: string;
  roles: string[];
  topics: string[];
  levels: number[];
  answers: Answer[];
  correctAnswerId: number;
}

interface TestData {
  testTitle: string;
  questions: Question[];
}

const testData = {
  testTitle: 'Coding Knowledge Test',
  questions: [
    {
      id: 1,
      questionText: 'What is JVM?',
      roles: ['backend', 'full-stack'], // multiple roles
      topics: ['java', 'system_design'], // multiple topics
      levels: [1, 2], // level
      answers: [
        { id: 1, text: 'Java Virtual Machine' },
        { id: 2, text: 'Java Volatile Memory' },
        { id: 3, text: 'Java Variable Memory' },
      ],
      correctAnswerId: 1,
    },
    {
      id: 2,
      questionText: 'What is a load balancer?',
      roles: ['backend', 'full-stack'],
      topics: ['system_design'],
      levels: [2, 3],
      answers: [
        { id: 1, text: 'Distributes traffic among servers' },
        { id: 2, text: 'Manages network switches' },
      ],
      correctAnswerId: 1,
    },
    {
      id: 3,
      questionText: "What does 'var' do in JavaScript?",
      roles: ['frontend', 'full-stack'],
      topics: ['javascript'],
      levels: [3],
      answers: [
        { id: 1, text: 'Declares a variable' },
        { id: 2, text: 'Creates a function' },
        { id: 3, text: 'Defines a constant' },
        { id: 4, text: 'Initiates a loop' },
      ],
      correctAnswerId: 1,
    },
  ],
};

export default function TestWIP() {
  const [selectedAnswers, setSelectedAnswers] = useState<{ [key: number]: number }>({});
  const [showResult, setShowResult] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const easyQuestions = testData.questions.filter((question) => question.levels.includes(1));

  const handleAnswerChange = (questionId: number, answerId: number) => {
    setSelectedAnswers((prev) => ({ ...prev, [questionId]: answerId }));
  };

  const handleSubmit = () => {
    setShowResult(true);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const calculateScore = () => {
    let score = 0;
    testData.questions.forEach((question) => {
      if (selectedAnswers[question.id] === question.correctAnswerId) {
        score += 1;
      }
    });
    return score;
  };

  return (
    <div>
      <Stack spacing={1} sx={{ backgroundColor: '', paddingBottom: 0 }}>
        <PageHeader title="Multiple Choice Test" />
        <Box sx={{ width: '100%', maxWidth: 600, margin: 'auto' }}>
          <Typography variant="h5" gutterBottom>
            {testData.testTitle}
          </Typography>

          {testData.questions.map((question) => (
            <Card key={question.id} sx={{ marginBottom: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {question.questionText}
                </Typography>
                <RadioGroup
                  value={selectedAnswers[question.id] || ''}
                  onChange={(e) => handleAnswerChange(question.id, parseInt(e.target.value))}
                >
                  {question.answers.map((answer) => (
                    <FormControlLabel key={answer.id} value={answer.id} control={<Radio />} label={answer.text} />
                  ))}
                </RadioGroup>
              </CardContent>
            </Card>
          ))}

          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: 2 }}>
            Submit
          </Button>

          {showResult && (
            <Typography variant="h5" sx={{ marginTop: 4 }}>
              Your score: {calculateScore()} / {testData.questions.length}
            </Typography>
          )}

          <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              Test submitted!
            </Alert>
          </Snackbar>
        </Box>
      </Stack>
    </div>
  );
}
