import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { InHouseProblem, LeetcodeLevels, SubmissionsResponse } from 'interfaces/interfaces';
import { apiServerLink, localStorageKeys, routes, themeColors } from 'sitevars';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  Button,
  Typography,
} from '@mui/material';
import { DifficultyDivStyled } from './DataTable';

interface InHouseProblemsTableProps {
  data: InHouseProblem[];
  submissions: SubmissionsResponse;
}

export default function InHouseProblemsTable({ data, submissions }: InHouseProblemsTableProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleQuestionClick = (question_id: number, title: string, difficulty: string) => {
    const formattedTitle = title.toLowerCase().replace(/\s+/g, '-');
    const formattedDifficulty = difficulty.toLowerCase().replace(/\s+/g, '-');

    navigate(`${routes.problems}/${question_id}/${formattedTitle}/${formattedDifficulty}`);
  };

  return (
    <TableContainer component={Paper} style={{ maxHeight: '80vh' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 10 }}>Count</TableCell>
            <TableCell style={{ minWidth: 150 }}>Title</TableCell>
            <TableCell style={{ minWidth: 100 }}>Difficulty</TableCell>
            <TableCell style={{ minWidth: 250 }}>Tags</TableCell>
            <TableCell style={{ minWidth: 100 }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              onClick={() => handleQuestionClick(row.question_id, row.title, row.difficulty)}
              key={row.title}
              sx={{
                backgroundColor: index % 2 === 0 ? theme.palette.background.default : theme.palette.background.paper,
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                },
              }}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <DifficultyDivStyled difficulty={row.difficulty.toLowerCase()}>
                  {row.difficulty.toLowerCase()}
                </DifficultyDivStyled>
              </TableCell>
              <TableCell>{row.topic_tags.join(', ')}</TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    color:
                      submissions[row.question_id] === 1
                        ? themeColors.lightGreen
                        : submissions[row.question_id] === -1
                        ? themeColors.lightRed
                        : themeColors.grey400,
                  }}
                >
                  {submissions[row.question_id] === 1
                    ? 'Accepted'
                    : submissions[row.question_id] === -1
                    ? 'Failed'
                    : 'Unattempted'}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
