import React from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp';
import PlaylistAddCheckCircleSharpIcon from '@mui/icons-material/PlaylistAddCheckCircleSharp';
import { themeColors } from 'sitevars';
import { DiaryEntry, CheckinType } from 'interfaces/interfaces'; // Make sure these are correctly imported
import {
  areAllFileldsFilled,
  formatEpochToDateDDMonthYear,
  formatEpochToDayOfWeek,
  getMonthNumberFromEpoch,
  getWeekNumber,
} from 'utils/Helpers';

interface DiaryListProps {
  diaryEntries: DiaryEntry[];
  handleOpen: (entry: DiaryEntry) => void;
  isAdmin?: boolean;
}

const DiaryEntriesList: React.FC<DiaryListProps> = ({ diaryEntries, handleOpen, isAdmin }) => {
  return (
    <List>
      {diaryEntries.map((entry) => (
        <ListItem button key={entry.date} onClick={() => handleOpen(entry)}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            {areAllFileldsFilled(entry) ? (
              <CheckCircleOutlineSharpIcon sx={{ mr: 2, color: themeColors.deepGreen }} />
            ) : (
              <RadioButtonUncheckedSharpIcon sx={{ mr: 2, color: themeColors.grey400 }} />
            )}
            {isAdmin && entry.type !== CheckinType.DAILY ? (
              <PlaylistAddCheckCircleSharpIcon
                sx={{
                  mr: 2,
                  color: entry.comments && entry.comments !== 'Pending' ? themeColors.deepGreen : themeColors.grey400,
                }}
              />
            ) : null}
          </Box>
          {entry.type === CheckinType.DAILY && (
            <ListItemText
              primary={`${formatEpochToDateDDMonthYear(entry.date)} (${formatEpochToDayOfWeek(entry.date)})`}
              secondary="Daily Check In"
            />
          )}
          {entry.type === CheckinType.WEEKLY && (
            <ListItemText
              primary={`${formatEpochToDateDDMonthYear(entry.date)} (${formatEpochToDayOfWeek(
                entry.date
              )}) - Week ${getWeekNumber(entry.date)}`}
              secondary="Weekly Check In"
              style={{ color: themeColors.muiPrimaryBlue }}
            />
          )}
          {entry.type === CheckinType.MONTHLY && (
            <ListItemText
              primary={`${getMonthNumberFromEpoch(entry.date)} Review`}
              secondary="Monthly Check In"
              style={{ color: themeColors.muiPrimaryBlue }}
              primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
            />
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default DiaryEntriesList;
