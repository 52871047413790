import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import DateCalendarServerRequest from 'components/common/Celendar';
import InHouseProblemsTable from 'components/InHouseProblemsTable';
import { PageHeader } from 'components/common/PageHeader';
import Spinner from 'components/common/Spinner';
import { CalendarEventType, InHouseProblem, SubmissionsResponse, TopicTag } from 'interfaces/interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { apiServerLink, localStorageKeys } from 'sitevars';
import { useTheme } from '@mui/material';
import { getUserId } from 'utils/Helpers';
import { AppContext } from 'context/context';

function Problems() {
  const theme = useTheme();
  const [allQuestions, setAllQuestions] = useState<InHouseProblem[]>([]);
  const [allSubmissions, setAllSubmissions] = useState<SubmissionsResponse>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredQuestions, setFilteredQuestions] = useState<InHouseProblem[] | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedDifficulties, setSelectedDifficulties] = useState<string[]>([]);
  const { state } = useContext(AppContext);

  const difficulties = ['easy', 'medium', 'hard'];

  const tagCountMap = allQuestions.reduce((acc, problem) => {
    problem.topic_tags.forEach((tag) => {
      acc[tag] = (acc[tag] || 0) + 1;
    });
    return acc;
  }, {} as Record<string, number>);

  const allTags = Object.keys(tagCountMap)
    .filter((tag) => tagCountMap[tag] >= 10)
    .sort((a, b) => tagCountMap[b] - tagCountMap[a]);

  const handleTagChange = (tag: string) => {
    const updatedSelectedTags = selectedTags.includes(tag)
      ? selectedTags.filter((t) => t !== tag)
      : [...selectedTags, tag];

    setSelectedTags(updatedSelectedTags);
  };

  const handleDifficultyChange = (difficulty: string) => {
    setSelectedDifficulties((prev) =>
      prev.includes(difficulty) ? prev.filter((d) => d !== difficulty) : [...prev, difficulty]
    );
  };

  useEffect(() => {
    if (selectedTags.length === 0 && selectedDifficulties.length === 0) {
      setFilteredQuestions(null);
    } else {
      const filtered = allQuestions.filter(
        (problem) =>
          (selectedTags.length === 0 || selectedTags.some((tag) => problem.topic_tags.includes(tag))) &&
          (selectedDifficulties.length === 0 || selectedDifficulties.includes(problem.difficulty.toLowerCase()))
      );
      setFilteredQuestions(filtered);
    }
  }, [selectedTags, selectedDifficulties, allQuestions]);

  useEffect(() => {
    const data = localStorage.getItem(localStorageKeys.inhouseProblemsData);
    if (data === '' || data === null || data === undefined || data.length <= 0) {
      fetchAllQuestions();
    } else {
      const parsedData = JSON.parse(data);
      setAllQuestions(parsedData);
      setLoading(false);
    }
  }, []);

  const fetchAllQuestions = async () => {
    try {
      const userId = getUserId(state);

      const [questionsData, submissionsData] = await Promise.all([
        axios.get(`${apiServerLink}/api/all_questions`),
        axios.get(`${apiServerLink}/api/submissions/${userId}`),
      ]);

      const data = questionsData.data;
      const processedData = processData(data);

      const submissions = submissionsData.data;

      // localStorage.setItem(localStorageKeys.inhouseProblemsData, JSON.stringify(processedData));
      setAllQuestions(processedData);
      setAllSubmissions(submissions);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching in house problem questions:', error);
    }
  };

  const processData = (data: any[]) => {
    return data.map((item: any) => {
      try {
        var allTagNames: string[] = [];
        const topicTags = JSON.parse(item.topic_tags || '[]') as TopicTag[];

        const tagNames = topicTags.map((tag) => tag.name);
        allTagNames.push(...tagNames);

        return {
          ...item,
          topic_tags: allTagNames,
        };
      } catch (error) {
        console.error('Error parsing topic tags:', error);
        return {
          ...item,
          topic_tags: 'N/A',
        };
      }
    });
  };

  return (
    <Stack sx={{ backgroundColor: '', height: '90vh', width: '90vw' }}>
      <PageHeader title="Explore Problems" />
      {loading && <Spinner />}
      {!loading && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8} sx={{ backgroundColor: '' }}>
              <InHouseProblemsTable data={filteredQuestions ?? allQuestions} submissions={allSubmissions} />
            </Grid>
            <Grid item xs={12} lg={4} sx={{ backgroundColor: '' }}>
              <DateCalendarServerRequest
                eventType={CalendarEventType.SIMPLE}
                sx={{
                  width: '20vw',
                }}
              />
              <Box
                sx={{
                  maxWidth: '20vw',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 5,
                  padding: 1,
                  marginBottom: 3,
                }}
              >
                <Typography sx={{ marginLeft: 1 }}>Select Difficulty:</Typography>
                {difficulties.map((difficulty) => (
                  <FormControlLabel
                    key={difficulty}
                    control={
                      <Checkbox
                        size="small"
                        checked={selectedDifficulties.includes(difficulty)}
                        onChange={() => handleDifficultyChange(difficulty)}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: 13 }}>
                        {difficulty.charAt(0).toUpperCase() + difficulty.slice(1)}
                      </Typography>
                    }
                    sx={{
                      margin: 0,
                      padding: 0,
                    }}
                  />
                ))}
              </Box>
              <Box
                sx={{
                  maxWidth: '20vw',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 5,
                  padding: 1,
                  marginBottom: 10,
                }}
              >
                <Typography sx={{ marginLeft: 1 }}>Select Tags:</Typography>
                {allTags.map((tag) => (
                  <FormControlLabel
                    key={tag}
                    control={
                      <Checkbox
                        size="small"
                        checked={selectedTags.includes(tag)}
                        onChange={() => handleTagChange(tag)}
                      />
                    }
                    label={<Typography sx={{ fontSize: 12 }}>{`${tag} (${tagCountMap[tag]})`}</Typography>}
                    sx={{
                      margin: 0,
                      padding: 0,
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Stack>
  );
}

export default Problems;
