export const apiBaseLink = process.env.REACT_APP_API as string;
export const webAppLink = process.env.REACT_APP_WEB_APP as string;
export const googleAuthClientId = process.env.REACT_APP_GOOGLE_AUTH_CLINET_ID as string;
export const googleApiKey = process.env.REACT_APP_GOOGLE_AUTH_CLINET_ID as string;
export const shouldUseTestUser = process.env.REACT_APP_USE_TEST_USER as string;
export const testUserEmail = process.env.REACT_APP_USE_TEST_EMAIL as string;

export const apiAuthLink = `${apiBaseLink}/auth`;
export const apiUserLink = `${apiBaseLink}/user`;

export const apiServerLink = apiBaseLink; //`http://127.0.0.1:5050`;
export const useTestUser = shouldUseTestUser === '1' ? true : false;

export const routes = {
  root: '/',
  home: '/home',
  tracker: '/tracker',
  targets: '/targets',
  tests: '/tests',
  liveTest: '/live-test',
  pageNotFound: '/pageNotFound',
  problems: '/problems',
  oboarding: '/onboarding',
  jobsTracker: 'jobs-tracker',
  checkin: 'check-in',
  // admin routes below
  admin: '/admin',
  adminTestGrading: '/admin-test-grading',
  adminCheckin: '/admin-check-in',
  adminJobs: '/admin-jobs',
};

export const webRoutes = {
  gettingStarted: '/getting-started-guide',
  faq: '/faq',
  security: '/security',
  pricing: '/plans',
};

export const deviceSize = {
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1440,
};

export const layoutConstant = {
  topbarHeight: 40,
  grocerySidenavWidth: 280,
  containerWidth: 1200,
  mobileNavHeight: 64,
  headerHeight: 80,
  mobileHeaderHeight: 64,
};

export const horizontalStyle = {
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
  margin: 0,
};

export const fullPageStyle = {
  height: '100%',
  position: 'absolute',
  width: '100%',
  overflow: 'hidden',
};

export const themeColors = {
  blackBlue: '#0c0e30',
  cherryRedBright: '#d64444',
  cherryRedDeep: '#a4014e',

  primaryBlue: '#0d87e9',

  darkBlue: '#01345E',

  // pie chart
  deepGreen: '#99c140',
  deepBlue: '#219ebc',
  deepRed: '#cc3232',

  lightGreen: '#B1CD75',
  lightBlue: '#4db8d7',
  lightRed: '#e85454',
  lightGrey: '#bcbcbc',
  lightYellow: '#EED773',

  dimGrey: '#696969',
  dimBlue: '#A8CAED',

  grey400: '#494949',
  grey500: '#2c2c2c',
  grey600: '#262626',
  grey700: '#1E1E1E',
  dimLightGrey: '#b5b5b5',

  greyIcons: '#404040',

  greenSubmit: '#4CC575',

  skyLightBlue: '#D5ECFF',

  white: '#ffffff',

  muiPrimaryBlue: '#42a5f5',
  muiDarkBlue: '#0288d1',
  muiDarkGreen: '#66bb6a',
  muiLightBlue: '#90caf9',
  muiPrimaryGreen: "#66bb6a'",
};

export const FULL_LOGO_DARK_THEME = '';
export const FULL_LOGO_LIGHT_THEME = '';
export const MINI_LOGO_DARK_THEME = '';
export const MINI_LOGO_LIGHT_THEME = '';

export const localStorageKeys = {
  tasksData: 'tasksData',
  testsData: 'testsData',
  inhouseProblemsData: 'inhouseProblemsData',
  dailyData: 'dailyData',
  timeLeft: 'timeLeft',
  userData: 'userData',
  startTime: 'startTime',
  isTestSessionActive: 'isTestSessionActive',
  testLanguage: 'testLanguage',
  savedCode: 'savedCode',
  googleUserData: 'googleUserData',
  isUserAuthorized: 'isUserAuthorized',
  calendarEvents: 'calendarEvents',
  selectedLanguage: 'selectedLanguage',
  ideFontSize: 'ideFontSize',
  readOnly: 'readOnly',
};
