import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { Typography } from '@mui/material';

export interface ChartProps {
  id: number;
  value: number;
  label: string;
  color: string;
}

interface PieChartVisualsizationProps {
  chartProps: ChartProps[];
  title?: string;
}

export default function PieChartVisualsization({ chartProps, title }: PieChartVisualsizationProps) {
  return (
    <>
      {title && <Typography variant="h5">{title}</Typography>}
      <PieChart
        series={[
          {
            data: chartProps,
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            innerRadius: 30,
            outerRadius: 100,
            paddingAngle: 3,
            cornerRadius: 5,
          },
        ]}
        height={200}
        width={500}
      />
    </>
  );
}
