import {
  Stack,
  Box,
  Typography,
  Tab,
  Tabs,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Divider,
  Grid,
  Tooltip,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { IdeProblem, LeetcodeLevels, programmingLanguages, Submission } from 'interfaces/interfaces';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiServerLink, localStorageKeys, themeColors } from 'sitevars';
import Spinner from './common/Spinner';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import Editor from '@monaco-editor/react';
import { getDifficultyIconColor, getUserId } from 'utils/Helpers';
import StarFillRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { AppContext } from 'context/context';
import LoadingDots from './common/Loading';
import { formatTimestamp } from 'utils/commons';
import { Buffer } from 'buffer';
import RestartAltSharpIcon from '@mui/icons-material/RestartAltSharp';
import IdeSettingsModal from './buttons/IdeSettingsModal';
import { DifficultyDivStyled } from './DataTable';
import { NotificationTypeEnum } from 'strings';
import ToastAction from './common/ToastAction';
import TimerComponent from './common/TimerComponent';
import EmojiFlagsSharpIcon from '@mui/icons-material/EmojiFlagsSharp';
import FlagSharpIcon from '@mui/icons-material/FlagSharp';
import BookmarkSharpIcon from '@mui/icons-material/BookmarkSharp';
import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';

export const saveSolution = (taskId: string, solution: string, language: string) => {
  localStorage.setItem(`task_${taskId}_lang_${language}`, solution);
};

export const getSolution = (taskId: string, language: string): string | null => {
  return localStorage.getItem(`task_${taskId}_lang_${language}`);
};

export const removeSolution = (taskId: string, language: string) => {
  localStorage.removeItem(`task_${taskId}_lang_${language}`);
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

const ProblemEditor = () => {
  const { question_id, title, difficulty } = useParams<{
    question_id: string;
    title: string;
    difficulty: string;
  }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const { state } = useContext(AppContext);
  const [problem, setProblem] = useState<IdeProblem | null>(null);
  const [output, setOutput] = useState<string[] | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(() => {
    return localStorage.getItem(localStorageKeys.selectedLanguage) || 'python';
  });

  const [fontSize, setFontSize] = useState<number>(() => {
    const cachedSize = localStorage.getItem(localStorageKeys.ideFontSize);
    return cachedSize ? Number(cachedSize) || 12 : 12;
  });

  const editorRef = useRef<any>(null);
  const [editorHeight, setEditorHeight] = useState<number>(100); // Initial height
  const [isFeedbackLoading, setIsFeedbackLoading] = useState<boolean>(false);
  const [isFavourite, setIsFavourite] = useState<boolean>();
  const [isFlagged, setIsFlagged] = useState<boolean>();
  const [questionNote, setQuestionNote] = useState<string>('');

  const LINE_HEIGHT = 20; // Adjust this based on your font size and line height
  const EXTRA_LINES = 2; // Number of extra lines for padding

  const difficultyColor = getDifficultyIconColor(difficulty?.toLowerCase() as LeetcodeLevels);

  //notification toast vars
  const [notification, setNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationType, setNotificationType] = useState<
    typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum]
  >(NotificationTypeEnum.INFO);

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationText('');
    setNotificationType(NotificationTypeEnum.INFO);
  };

  const handleChangeLanguage = (event: any) => {
    const selectedLang = event.target.value as string;
    setSelectedLanguage(selectedLang);
    localStorage.setItem(localStorageKeys.selectedLanguage, selectedLang);
  };

  const updateEditorHeight = useCallback(() => {
    if (editorRef.current) {
      const model = editorRef.current.editor.getModel();
      if (model) {
        const numberOfLines = model.getLineCount();
        const newHeight = numberOfLines * LINE_HEIGHT + EXTRA_LINES * LINE_HEIGHT;
        setEditorHeight(newHeight);
      }
    }
  }, [problem?.solutions]);

  useEffect(() => {
    // Initial height update
    updateEditorHeight();

    // Event listener for content changes
    const subscription = editorRef.current?.editor.onDidChangeModelContent(() => {
      updateEditorHeight();
    });

    // Cleanup on unmount
    return () => {
      subscription?.dispose();
    };
  }, [updateEditorHeight, problem?.solutions]);

  useEffect(() => {
    fetchProblemData();
  }, [selectedLanguage, question_id]);

  const fetchProblemData = async () => {
    try {
      const user_id = getUserId(state);
      if (!question_id || !user_id) {
        setLoading(false);
        navigate('/404');
        return;
      }
      // Fetch all data concurrently
      const [
        titleResponse,
        snippetResponse,
        // testCasesResponse, //Not used for now
        submissionsResponse,
        contentResponse,
        solutionsResponse,
        favouriteStatusResponse,
        flaggedResponse,
        noteResponse,
      ] = await Promise.all([
        axios.get(`${apiServerLink}/api/question/title/${question_id}`),
        axios.get(`${apiServerLink}/api/question/code_snippet?question_id=${question_id}&language=${selectedLanguage}`),
        // axios.get(`${apiServerLink}/api/question/test_cases/${question_id}`),
        axios.get(`${apiServerLink}/api/question/submissions`, { params: { user_id, question_id } }),
        axios.get(`${apiServerLink}/api/question/content/${question_id}`),
        axios.get(`${apiServerLink}/api/question/solution/${question_id}`),
        axios.get(`${apiServerLink}/api/get_favourite_status`, { params: { user_id, question_id } }),
        axios.get(`${apiServerLink}/api/get_flagged_status`, { params: { user_id, question_id } }),
        axios.get(`${apiServerLink}/api/get_note`, { params: { user_id, question_id } }),
      ]);

      const title = titleResponse.data;
      const code = snippetResponse.data?.code || '';
      // const testCases = testCasesResponse.data
      //   ? JSON.stringify(testCasesResponse.data, null, 2)
      //   : 'Corrupted Test Case. We are on it!';
      const submissions = submissionsResponse.data || '';
      const questionContent = contentResponse.data || '';
      const solutions = solutionsResponse.data || '';

      const savedCode = getSolution(question_id, selectedLanguage);
      var currentCode = code;
      if (savedCode && code !== savedCode) {
        currentCode = savedCode;
      }

      const data = {
        id: question_id,
        title,
        code: currentCode,
        initialCode: code,
        // testCases,
        submissions,
        questionContent,
        solutions,
      };

      setProblem(data);
      setIsFavourite(favouriteStatusResponse.data.is_favourite);
      setIsFlagged(flaggedResponse.data.is_flagged);
      setQuestionNote(noteResponse.data.note);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedSubmission, setSlectedSubmission] = useState<Submission | null>(null);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSubmissionClick = (submission: Submission) => {
    if (submission.submission_time === selectedSubmission?.submission_time) {
      setSlectedSubmission(null);
    } else {
      setSlectedSubmission(submission);
    }
  };

  function handleEditorChange(value: any, event: any) {
    saveSolution(question_id!, value, selectedLanguage);
    setProblem((prevProblem) => {
      if (prevProblem) {
        return {
          ...prevProblem,
          code: value,
        };
      } else {
        console.log('Error while handingling editor code change');
        return null;
      }
    });
  }

  function formatTestCase(jsonObj: Record<string, any>): string {
    let formattedPairs: string[] = [];
    for (var key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        let value = jsonObj[key];
        if (Array.isArray(value)) {
          formattedPairs.push(`${key} = [${value.join(',')}]`);
        } else if (typeof value === 'string') {
          formattedPairs.push(`${key} = "${value}"`);
        } else if (typeof value === 'object' && value !== null) {
          formattedPairs.push(`${key} = ${JSON.stringify(value)}`);
        } else {
          formattedPairs.push(`${key} = ${String(value)}`);
        }
      }
    }
    return formattedPairs.join(', ');
  }

  const executeCode = async (code: string) => {
    try {
      const userId = getUserId(state);
      const response = await axios.post(`${apiServerLink}/execute`, {
        code,
        question_id,
        language: selectedLanguage,
        user_id: userId,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const serverMessage = error.response.data.message || 'An error occurred';
        console.error('Error executing code:', serverMessage);
        return { error: serverMessage };
      } else {
        console.error('Error executing code:', error);
        return { error: 'An unexpected error occurred' };
      }
    }
  };

  const resetToDefaultCode = () => {
    if (problem) {
      setProblem({
        ...problem,
        code: problem.initialCode,
      });
    }
  };

  const handleAddToFavourites = async () => {
    handleFavourites(true, 'Added to favourites');
  };

  const handleAddToFlagged = async () => {
    handleFlagged(true, 'Added to flagged');
  };

  const handleRemoveFromFavourites = async () => {
    handleFavourites(false, 'Removed from favourites');
  };

  const handleRemoveFromFlagged = async () => {
    handleFlagged(false, 'Removed from flagged');
  };

  const handleFavourites = async (isFavourite: boolean, successNotificationText: string) => {
    try {
      const userId = getUserId(state);
      const response = await axios.post(`${apiServerLink}/api/set_favourite`, {
        user_id: userId,
        question_id,
        is_favourite: isFavourite,
      });
      if (response.status === 200) {
        // notification for success
        setNotification(true);
        setNotificationText(successNotificationText);
        setNotificationType(NotificationTypeEnum.SUCCESS);
        setIsFavourite(isFavourite);
      }
    } catch (error) {
      // notification for error
      setNotification(true);
      setNotificationText('Sorry something went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const handleFlagged = async (isFlagged: boolean, successNotificationText: string) => {
    try {
      const userId = getUserId(state);
      const response = await axios.post(`${apiServerLink}/api/set_flagged`, {
        user_id: userId,
        question_id,
        is_flagged: isFlagged,
      });
      if (response.status === 200) {
        // notification for success
        setNotification(true);
        setNotificationText(successNotificationText);
        setNotificationType(NotificationTypeEnum.SUCCESS);
        setIsFlagged(isFlagged);
      }
    } catch (error) {
      // notification for error
      setNotification(true);
      setNotificationText('Sorry something went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const handleNoteSave = async () => {
    try {
      const userId = getUserId(state);
      const response = await axios.post(`${apiServerLink}/api/set_note`, {
        user_id: userId,
        question_id,
        note: questionNote,
      });
      if (response.status === 200) {
        // notification for success
        setNotification(true);
        setNotificationText('Note updated successfully.');
        setNotificationType(NotificationTypeEnum.SUCCESS);
        setIsFavourite(isFavourite);
      }
    } catch (error) {
      // notification for error
      setNotification(true);
      setNotificationText('Sorry something went wrong. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const handleNoteChange = (event: any) => {
    setQuestionNote(event.target.value);
  };

  const handleSubmit = async () => {
    setIsFeedbackLoading(true);
    if (problem != null) {
      const result = await executeCode(problem.code);
      setIsFeedbackLoading(false);
      if (result.error) {
        setOutput([`Error: ${JSON.stringify(result.error)}`]);
      } else if (result.final_status === 'Accepted') {
        setOutput([`Output: ${JSON.stringify(result.final_status)}`]);
      } else if (result.status.description === 'Wrong Answer') {
        const expectedOutput = Buffer.from(result.expected_output, 'base64').toString('utf-8');
        const stdoutString = Buffer.from(result.stdout, 'base64').toString('utf-8');
        const stdinStringParsed = JSON.parse(atob(result.stdin));
        const stdinString = formatTestCase(stdinStringParsed);

        var outputBuilder = [];
        if (expectedOutput) {
          outputBuilder.push(`Expected: ${expectedOutput}`);
        }
        if (stdoutString) {
          outputBuilder.push(`Result: ${stdoutString}`);
        }
        if (stdinString) {
          outputBuilder.push(`Test Case: ${stdinString}`);
        }
        setOutput(outputBuilder);
      } else {
        const stderrString = Buffer.from(result.stderr, 'base64').toString('utf-8');
        setOutput([`Error Description: ${JSON.stringify(stderrString)}`]);
      }
    }
  };

  return (
    <>
      {notification && (
        <ToastAction
          message={notificationText}
          type={notificationType}
          open={notification}
          close={handleCloseNotification}
        />
      )}
      <Box
        style={{ backgroundColor: 'black', height: '100vh', width: '100vw', margin: 0, padding: 0, overflow: 'hidden' }}
      >
        <Stack sx={{ backgroundColor: 'black', height: '95vh', width: '97vw', overflow: 'hidden' }}>
          {(loading || problem == null) && <Spinner />}
          {!loading && problem && (
            <PanelGroup
              direction="horizontal"
              style={{
                height: '100vh',
                width: '86vw',
                backgroundColor: 'black',
                margin: 16,
              }}
            >
              <Panel defaultSize={35} style={{ borderRadius: '10px' }}>
                <Stack sx={{ padding: 2, backgroundColor: themeColors.grey600, height: '100vh' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '' }}>
                    <Tabs
                      value={selectedTab}
                      onChange={handleChangeTab}
                      sx={{
                        '& .MuiTab-root': {
                          textTransform: 'none',
                          //   fontWeight: 'bold',
                          fontSize: '14px',
                          padding: '6px 6px',
                          color: themeColors.white,
                          minWidth: 'auto', // Prevent default width being applied
                          marginRight: '8px',
                        },
                      }}
                    >
                      <Tab label="Description" />
                      <Tab label="Solutions" />
                      <Tab label="Submissions" />
                      <Tab label="Notes" />
                    </Tabs>
                  </Box>

                  {/* Description Tab */}
                  <CustomTabPanel value={selectedTab} index={0}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        style={{
                          color: themeColors.white,
                          fontSize: fontSize + 2,
                          fontWeight: 'bold',
                          paddingTop: 10,
                          paddingBottom: 5,
                        }}
                      >
                        {question_id}. {problem.title}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 0,
                        backgroundColor: '',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: difficultyColor,
                          borderRadius: '8px',
                          padding: '1px',
                          paddingX: '12px',
                          display: 'inline-block',
                          color: themeColors.grey600,
                        }}
                      >
                        <Typography sx={{ fontSize: 12 }}>{difficulty}</Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          gap: 0,
                          backgroundColor: '',
                        }}
                      >
                        {isFavourite ? (
                          <Tooltip title="Remove from favourites">
                            <IconButton
                              id="star-filled-button"
                              sx={{ backgroundColor: '', p: 0, m: 0 }}
                              disableRipple
                              onClick={() => {
                                handleRemoveFromFavourites();
                              }}
                            >
                              <StarFillRoundedIcon sx={{ fontSize: 25, color: '#fdd835', paddingRight: 0 }} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Add from favourites">
                            <IconButton
                              id="star-outlined-button"
                              disableRipple
                              sx={{ backgroundColor: '', p: 0, m: 0 }}
                              onClick={() => {
                                handleAddToFavourites();
                              }}
                            >
                              <StarBorderRoundedIcon sx={{ fontSize: 25, color: '#797979', paddingRight: 0 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {isFlagged ? (
                          <Tooltip title="Remove from flagged">
                            <IconButton
                              id="star-filled-button"
                              sx={{ backgroundColor: '', p: 0, m: 0 }}
                              disableRipple
                              onClick={() => {
                                handleRemoveFromFlagged();
                              }}
                            >
                              <BookmarkSharpIcon
                                sx={{ fontSize: 25, color: themeColors.cherryRedDeep, paddingRight: 0 }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Add to flagged to review later">
                            <IconButton
                              id="star-outlined-button"
                              sx={{ backgroundColor: '', p: 0, m: 0 }}
                              disableRipple
                              onClick={() => {
                                handleAddToFlagged();
                              }}
                            >
                              <BookmarkBorderSharpIcon sx={{ fontSize: 25, color: '#797979', paddingRight: 0 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Box>

                    <Box
                      dangerouslySetInnerHTML={{ __html: problem.questionContent }}
                      sx={{
                        fontSize: fontSize,
                        marginTop: 1,
                        color: 'white',
                        height: '65vh',
                        overflowY: 'auto',
                      }}
                    />
                  </CustomTabPanel>

                  {/* Solutions Tab */}
                  <CustomTabPanel value={selectedTab} index={1}>
                    <Typography
                      style={{
                        color: themeColors.white,
                        paddingTop: 10,
                        fontSize: fontSize + 2,
                        fontWeight: 'bold',
                      }}
                    >
                      Solution for {problem.title}
                    </Typography>

                    <Box
                      sx={{
                        paddingTop: 1,
                        backgroundColor: '',
                        height: editorHeight,
                        maxHeight: '60vh',
                        overflowY: 'auto',
                      }}
                    >
                      <Editor
                        language={selectedLanguage}
                        theme="vs-dark"
                        value={problem.solutions}
                        options={{
                          automaticLayout: true,
                          minimap: {
                            enabled: false,
                          },
                          fontSize: fontSize,
                          wordWrap: 'on',
                          quickSuggestions: false,
                          renderValidationDecorations: 'off',
                          lineHeight: 20,
                          padding: { top: 10 },
                          scrollBeyondLastLine: false,
                          readOnly: true,
                        }}
                        onMount={(editor) => {
                          editorRef.current = { editor };
                          updateEditorHeight();
                        }}
                      />
                    </Box>
                  </CustomTabPanel>

                  {/* Submissions Tab */}
                  <CustomTabPanel value={selectedTab} index={2}>
                    <Box
                      sx={{
                        backgroundColor: '',
                        color: themeColors.white,
                        maxHeight: '70vh',
                        overflowY: 'auto',
                      }}
                    >
                      {problem.submissions.length > 0 ? (
                        <>
                          <Typography
                            style={{
                              color: themeColors.white,
                              paddingTop: 10,
                              paddingBottom: 20,
                              fontSize: fontSize + 2,
                              fontWeight: 'bold',
                            }}
                          >
                            All submissions for {problem.title}
                          </Typography>
                          {problem.submissions
                            .slice()
                            .reverse()
                            .map((submission, index) => (
                              <Box
                                key={index}
                                onClick={() => handleSubmissionClick(submission)}
                                sx={{
                                  borderRadius: 2,
                                  backgroundColor: themeColors.grey700,
                                  padding: 0,
                                  margin: 0,
                                  marginBottom: 3,
                                  cursor: 'pointer',
                                  transition: 'background-color 0.3s ease',
                                  '&:hover': {
                                    backgroundColor: themeColors.grey400,
                                  },
                                }}
                              >
                                {submission !== undefined && submission !== null && (
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} key={submission.time}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'flex-start',
                                          flexWrap: 'wrap',
                                          backgroundColor: '',
                                          marginBottom: 1,
                                          marginX: 1,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontWeight: 'bold',
                                            fontSize: fontSize,
                                            color:
                                              submission.final_status === 'Accepted'
                                                ? themeColors.lightGreen
                                                : themeColors.lightRed,
                                          }}
                                        >
                                          {submission.final_status}
                                        </Typography>

                                        <Typography sx={{ fontSize: fontSize }}>
                                          {formatTimestamp(submission.submission_time as number)}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} key={submission.time}>
                                      <Button
                                        disableElevation
                                        disabled
                                        sx={{
                                          fontSize: fontSize - 1,
                                          backgroundColor: themeColors.grey500,
                                          textTransform: 'none',
                                          color: 'white',
                                          margin: 0,
                                          padding: 0,
                                          borderRadius: 30,
                                          paddingX: 1,
                                          fontWeight: 0,
                                          '&:disabled': {
                                            color: 'white',
                                          },
                                        }}
                                      >
                                        {submission.language.name}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}

                                {selectedSubmission &&
                                  selectedSubmission.submission_time === submission.submission_time && (
                                    <Box
                                      sx={{
                                        paddingTop: 1,
                                        backgroundColor: '',
                                        height: editorHeight,
                                        maxHeight: '60vh',
                                        overflowY: 'auto',
                                      }}
                                    >
                                      <Editor
                                        language={selectedSubmission.language.name || 'python'}
                                        theme="vs-dark"
                                        value={selectedSubmission.code}
                                        options={{
                                          automaticLayout: true,
                                          minimap: {
                                            enabled: false,
                                          },
                                          fontSize: fontSize,
                                          wordWrap: 'on',
                                          quickSuggestions: false,
                                          renderValidationDecorations: 'off',
                                          lineHeight: 20,
                                          padding: { top: 10 },
                                          scrollBeyondLastLine: false,
                                          readOnly: true,
                                        }}
                                        onMount={(editor) => {
                                          editorRef.current = { editor };
                                          updateEditorHeight();
                                        }}
                                      />
                                    </Box>
                                  )}
                              </Box>
                            ))}
                        </>
                      ) : (
                        <Typography
                          style={{
                            color: themeColors.white,
                            paddingTop: 10,
                            paddingBottom: 10,
                            fontSize: fontSize + 2,
                            fontWeight: 'bold',
                          }}
                        >
                          No submissions yet.
                        </Typography>
                      )}
                    </Box>
                  </CustomTabPanel>

                  {/* Notes Tab */}
                  <CustomTabPanel value={selectedTab} index={3}>
                    <Typography
                      style={{
                        color: themeColors.white,
                        paddingTop: 10,
                        paddingBottom: 5,
                        fontSize: fontSize + 2,
                        fontWeight: 'bold',
                      }}
                    >
                      Notes for {problem.title}
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', paddingTop: 1 }}>
                      <TextField
                        label="Add your notes"
                        multiline
                        rows={10}
                        value={questionNote}
                        onChange={handleNoteChange}
                        variant="outlined"
                        fullWidth
                      />
                      <Button variant="contained" color="primary" onClick={handleNoteSave} sx={{ fontSize: 12 }}>
                        Save Note
                      </Button>
                    </Box>
                  </CustomTabPanel>
                </Stack>
              </Panel>

              <PanelResizeHandle>
                <Box
                  sx={{
                    width: '8px',
                    height: '100vh',
                    backgroundColor: 'black',
                    cursor: 'col-resize',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '2px',
                      height: '100%',
                      backgroundColor: 'black',
                      mx: 'auto',
                      '&:hover': {
                        backgroundColor: themeColors.muiPrimaryBlue,
                      },
                    }}
                  />
                </Box>
              </PanelResizeHandle>

              <Panel defaultSize={70} style={{ borderRadius: '0', backgroundColor: 'black' }}>
                <PanelGroup
                  direction="vertical"
                  style={{
                    height: '100%',
                    width: '90%',
                    backgroundColor: 'black',
                  }}
                >
                  <Panel defaultSize={80} style={{ borderRadius: '10px', backgroundColor: themeColors.grey600 }}>
                    <Box sx={{ padding: 2, backgroundColor: '', height: '92%', overflow: 'hidden' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingRight: '10px',
                          paddingBottom: '10px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <FormControl sx={{ width: '20', color: 'white', margin: '0px', padding: '0px', pr: 2 }}>
                            <Select
                              labelId="language-select-label"
                              id="language-select"
                              value={selectedLanguage}
                              onChange={handleChangeLanguage}
                              sx={{
                                padding: '0px',
                                margin: '0px',
                                fontSize: 12,
                                color: themeColors.dimLightGrey,
                                '.MuiOutlinedInput-input': {
                                  padding: '4px 8px', // Padding inside the input text area
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                  borderColor: themeColors.dimGrey,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: themeColors.dimGrey,
                                },
                                '.MuiSvgIcon-root': {
                                  color: themeColors.dimGrey,
                                },
                              }}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    bgcolor: themeColors.grey600,
                                    color: 'white',
                                  },
                                },
                              }}
                            >
                              {Object.entries(programmingLanguages).map(([key, value]) => (
                                <MenuItem key={key} value={key} sx={{ fontSize: 12 }}>
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TimerComponent />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <Tooltip title="Reset to default code definition">
                            <RestartAltSharpIcon
                              sx={{ color: themeColors.lightGrey, marginRight: 1, fontSize: 20 }}
                              onClick={resetToDefaultCode}
                            />
                          </Tooltip>
                          <IdeSettingsModal fontSize={fontSize} setFontSize={setFontSize} />

                          <Button
                            onClick={handleSubmit}
                            sx={{
                              textTransform: 'none',
                              backgroundColor: themeColors.greenSubmit,
                              paddingX: 2,
                              paddingY: 0,
                              marginLeft: 1,
                              fontSize: 12,
                              color: 'white',
                              '&:hover': {
                                backgroundColor: 'darkgreen',
                              },
                            }}
                          >
                            Submit
                          </Button>
                        </Box>
                      </Box>
                      <Editor
                        language={selectedLanguage}
                        theme="vs-dark"
                        value={problem.code}
                        onChange={handleEditorChange}
                        options={{
                          automaticLayout: true,
                          minimap: {
                            enabled: false,
                          },
                          fontSize: fontSize,
                          wordWrap: 'on',
                          quickSuggestions: false,
                          renderValidationDecorations: 'off',
                          padding: { top: 10 },
                          renderLineHighlight: 'none',
                          renderLineHighlightOnlyWhenFocus: false,
                        }}
                      />
                    </Box>
                  </Panel>

                  <PanelResizeHandle>
                    <Box
                      sx={{
                        height: '8px',
                        backgroundColor: 'black',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'col-resize',
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          height: '2px',
                          backgroundColor: 'black',
                          '&:hover': {
                            backgroundColor: themeColors.muiPrimaryBlue,
                          },
                        }}
                      />
                    </Box>
                  </PanelResizeHandle>

                  <Panel
                    defaultSize={15}
                    style={{
                      borderRadius: '10px',
                      backgroundColor: themeColors.grey600,
                      padding: 0,
                      color: 'white',
                      display: 'flex',
                      height: '100%',
                    }}
                  >
                    <Box sx={{ padding: 2, backgroundColor: '' }}>
                      {!isFeedbackLoading && !output && (
                        <Typography sx={{ fontSize: fontSize }}>
                          <span style={{ fontWeight: 'bold' }}>Output:</span> Please submit your solution to get
                          feedback.
                        </Typography>
                      )}
                      {isFeedbackLoading && <LoadingDots />}
                      {!isFeedbackLoading && output && (
                        <Box
                          sx={{
                            backgroundColor: '',
                            color: themeColors.white,
                            height: '100%',
                            width: '100%',
                            overflowY: 'hidden',
                          }}
                        >
                          <Typography sx={{ fontSize: fontSize }}>
                            <span style={{ fontWeight: 'bold' }}>Output:</span>
                          </Typography>
                          {output.map((line, index) => (
                            <Typography key={index} sx={{ fontSize: fontSize }}>
                              {line}
                            </Typography>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Panel>
                </PanelGroup>
              </Panel>
            </PanelGroup>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default ProblemEditor;
